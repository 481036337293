<template>
  <div class="lanternRiddles">
    <div class="lanternRiddles-t">
      <div class="item-t">
        <img src="./img/back-t.png" alt="" />
      </div>
      <div class="item-c">
        <img src="./img/back-c.png" alt="" />
      </div>
      <div class="item-b">
        <img src="./img/back-b.png" alt="" />
      </div>
    </div>
    <div class="box-t">
      <div class="title"><img src="./img/resInfo.png" alt="" /></div>
      <!-- <div class="info">快来拿走属于你的奖品吧</div> -->
      <div class="res">
        已答对题数：{{ countData >= 5 ? 5 : countData }} / 5
      </div>
      <div class="boxBody">{{ randomDengmi.question }}</div>
      <div class="input">
        <input type="text" v-model="postData" placeholder="请填写答案" />
      </div>
      <div class="submit" v-if="isInit" @click="submit">提交</div>
      <div class="submit" v-else @click="toNext">下一题</div>
    </div>
  </div>
</template>

<script>
import {
  getUserAnswerRightCountUrl,
  saveDengmiUserRecordUrl,
  getRandomDengmiUrl,
} from "./api";
import { toRegister } from "@/utils/common.js";
export default {
  name: "lanternRiddles",
  components: {},
  props: {},
  data() {
    return {
      countData: 0,
      randomDengmi: {},
      postData: "",
      isInit: true,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
      if (this.tenantId != "P1C2A2S1D1") {
        this.$toast({ message: "当前社区无权限，请切换社区", duration: 2000 });
        return;
      }
      this.getUserAnswerRightCount();
      this.getRandomDengmi();
    }
  },
  methods: {
    async toNext() {
      this.isInit = true;
      this.postData = "";

      this.getRandomDengmi();
    },
    async submit() {
      console.log(this.postData, "000");
      if (this.postData == "") {
        this.$toast({ message: "请填写答案", duration: 2000 });
        return;
      }
      let arr = [];
      if (this.randomDengmi && this.randomDengmi.answer) {
        arr = this.randomDengmi.answer.split(";");
      }
      if (!arr.includes(this.postData)) {
        this.$toast({ message: "答案不正确，请重新答题", duration: 2000 });
        return;
      }
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        dengmiId: this.randomDengmi.id,
      };
      let res = await this.$axios.post(`${saveDengmiUserRecordUrl}`, params);
      if (res.code == 200) {
        this.$toast({ message: "答案正确，请点击下一题切换", duration: 2000 });
        this.isInit = false;
        this.getUserAnswerRightCount();
      }
    },
    async getUserAnswerRightCount() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getUserAnswerRightCountUrl}`, {
        params,
      });
      if (res.code == 200) {
        this.countData = res.data;
      }
    },
    async getRandomDengmi() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getRandomDengmiUrl}`, {
        params,
      });
      if (res.code == 200) {
        this.randomDengmi = res.data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.lanternRiddles {
  position: relative;
  .lanternRiddles-t {
    height: 100vh;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    .item-t {
      width: 100%;
      height: 504px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .item-c {
      flex: 1;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .item-b {
      width: 100%;
      height: 576px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .box-t {
    padding: 186px 0 0 0;
    position: absolute;
    z-index: 99;
    width: 100%;
    .title {
      width: 462px;
      height: 52px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      width: 424px;
      height: 60px;
      margin: 12px auto;
      position: relative;
      background: url("./img/signBack.png") no-repeat;
      background-size: 100% 100%;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 60px;
      text-align: center;
      &::before {
        content: "";
        width: 18px;
        height: 20px;
        background: url("./img/sign.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 20px;
        left: 22px;
      }
      &::after {
        content: "";
        width: 18px;
        height: 20px;
        transform: rotate(180deg);
        background: url("./img/sign.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 20px;
        right: 22px;
      }
    }
    .res {
      width: 310px;
      height: 60px;
      margin: 92px auto 0;
      background: url("./img/resBack.png") no-repeat;
      background-size: 100% 100%;
      font-size: 24px;
      font-weight: 700;
      color: #b60709;
      line-height: 60px;
      text-align: center;
    }
    .boxBody {
      width: 628px;
      height: 694px;
      margin: -30px auto;
      background: url("./img/miBody.png") no-repeat;
      background-size: 100% 100%;
      font-size: 40px;
      line-height: 58px;
      font-weight: 800;
      text-align: center;
      padding: 128px 98px 88px;
      box-sizing: border-box;
      color: #b60709;
      display: flex;
      align-items: center;
    }
    .input {
      width: 628px;
      height: 104px;
      margin: 64px auto 52px;
      background: #ffd5c4;
      border-radius: 16px;
      border: 8px solid #d63816;
      padding-left: 48px;
      box-sizing: border-box;
      input {
        width: 100%;
        height: 100%;
        background: none;
        font-size: 32px;
        font-weight: 700;
        color: #b60709;
        line-height: 104px;
        &::placeholder {
          color: #b60709;
        }
      }
    }
    .submit {
      width: 530px;
      height: 104px;
      background: url("./img/submit.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;
      line-height: 104px;
      text-align: center;
    }
  }
}
</style>
