// 用户已答对题数查询
const getUserAnswerRightCountUrl = `/gateway/hc-neighbour/dengmi/getUserAnswerRightCount`;
// 灯谜随机抽取
const getRandomDengmiUrl = `/gateway/hc-neighbour/dengmi/getRandomDengmi`;
// 答对的场合记录保存
const saveDengmiUserRecordUrl = `/gateway/hc-neighbour/dengmi/saveDengmiUserRecord`;

export {
  getUserAnswerRightCountUrl,
  saveDengmiUserRecordUrl,
  getRandomDengmiUrl,
};
